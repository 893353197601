import { QEQuestion, QEAnswer, Questionaire } from '../models/QEQuestion';
import { AnnouncementState, QuestionState } from '../models/AppState';
import { ClientAnnouncerSource } from '../models/AppConfiguration';
import { capitalize } from './capitalize';
import { computeTagsFromVDS } from './computeTagsFromVDS';
import { computeTagsFromDDS } from './computeTagsFromDDS';
import { Unit } from '../models/Unit';

let QUESTIONNAIRE_CACHE: {
  questionnaire: QEQuestion[];
  [cacheKey: string]: any;
} = {
  questionnaire: []
};

const cacheByQuestionnaire = <TValue>(
  questionnaire: QEQuestion[],
  key: string,
  computeCallback: () => TValue
): TValue => {
  if (QUESTIONNAIRE_CACHE.questionnaire !== questionnaire) {
    QUESTIONNAIRE_CACHE = { questionnaire };
  }
  return QUESTIONNAIRE_CACHE[key] || (QUESTIONNAIRE_CACHE[key] = computeCallback());
};

const computeVehicleTags = async (unit: Unit, useDDS: boolean | undefined) => {
  const tags = useDDS ? await computeTagsFromDDS(unit) : await computeTagsFromVDS(unit);
  return tags;
};

/**
 * Returns the announcer source of the announcement related to the question's answer(s)
 */
export const findIdentifiedBy = (
  question: QEQuestion,
  questionnaire: QEQuestion[],
  announcements: AnnouncementState[]
) =>
  announcements.reduce<string[]>(
    (acc, announcement) =>
      isQuestionRelatedToAnnouncement(question, announcement.code, questionnaire) &&
      !acc.includes(capitalize(announcement.source))
        ? [...acc, capitalize(announcement.source)]
        : acc,
    []
  );

/**
 * Returns a cached array of all hierarchical parents of a specific question
 */
export const findParents = (question: QEQuestion, questionnaire: QEQuestion[]): QEQuestion[] => {
  const parentsByGuid = cacheByQuestionnaire(questionnaire, 'parentsByGuid', () => {
    const fp = (questionToFind: QEQuestion): QEQuestion[] => {
      const parents = questionnaire.filter(q =>
        q.answers.some(
          a => a.requiredQuestions?.includes(questionToFind.guid) || a.nestedQuestions?.includes(questionToFind.guid)
        )
      );
      return parents.reduce<QEQuestion[]>((acc, parent) => {
        const grandparents = fp(parent); // Recursion
        return [...acc, ...grandparents, parent];
      }, []);
    };
    return questionnaire.reduce<{ [guid: string]: QEQuestion[] }>((acc, q) => {
      return {
        ...acc,
        [q.guid]: fp(q)
      };
    }, {});
  });

  return parentsByGuid[question.guid];
};

/**
 * Returns a cached array of all hierarchical children of a specific question
 */
export const findChildren = (question: QEQuestion, questionnaire: QEQuestion[]): QEQuestion[] => {
  const childrenByGuid = cacheByQuestionnaire(questionnaire, 'childrenByGuid', () => {
    const fc = (questionToFind: QEQuestion): QEQuestion[] => {
      const found = questionToFind.answers.reduce<QEQuestion[]>((acc, answer) => {
        const requiredChildren = answer.requiredQuestions?.map(guid => getQuestionByGuid(guid, questionnaire)!) || [];
        const nestedChildren = answer.nestedQuestions?.map(guid => getQuestionByGuid(guid, questionnaire)!) || [];
        const children = [...requiredChildren, ...nestedChildren];
        const grandchildren = children.reduce<QEQuestion[]>((acc2, child) => {
          return [...acc2, ...fc(child)];
        }, []);
        return [...acc, ...children, ...grandchildren];
      }, []);
      return questionnaire.filter(q => found.includes(q));
    };
    return questionnaire.reduce<{ [guid: string]: QEQuestion[] }>((acc, q) => {
      return {
        ...acc,
        [q.guid]: fc(q)
      };
    }, {});
  });

  return childrenByGuid[question.guid];
};

export const removeResponsesForOptionalQuestions = (questionResponses: QuestionState[], questionnaire: QEQuestion[]) =>
  questionnaire.reduce<QuestionState[]>((acc, question) => {
    if (isQuestionRequired(question, questionResponses, questionnaire)) {
      const response = questionResponses.find(r => r.guid === question.guid);
      return response ? [...acc, response] : acc;
    }
    return acc;
  }, []);

/**
 * Returns a boolean representing the stability of the specified question
 *
 * Stable means that the app requires no more input to determine the final state of the question
 * */
// eslint-disable-next-line no-underscore-dangle
export const isQuestionStable = ({
  question,
  questionnaire,
  questionResponses
}: {
  question: QEQuestion;
  questionnaire: QEQuestion[];
  questionResponses: QuestionState[];
}) => {
  const hasAnswers = answersForQuestion(question, questionResponses).length > 0;
  if (hasAnswers) {
    return true;
  }

  const isRequired = isQuestionRequired(question, questionResponses, questionnaire);
  if (isRequired) {
    return false;
  }

  const hasParentsThatAreUnansweredAndRequired = findParents(question, questionnaire).some(
    parent =>
      isQuestionRequired(parent, questionResponses, questionnaire) &&
      answersForQuestion(parent, questionResponses).length === 0
  );

  return !hasParentsThatAreUnansweredAndRequired;
};

export const questionAgreesWithAnnouncementOrIsUnstable = ({
  guid,
  code,
  questionnaire,
  questionResponses,
  announcements
}: {
  guid: string;
  code: string;
  questionnaire: QEQuestion[];
  questionResponses: QuestionState[];
  announcements: AnnouncementState[];
}) => {
  const question = getQuestionByGuid(guid, questionnaire);

  const mappedAnswers = question.answers.filter(a => a.announcements?.includes(code));
  if (mappedAnswers.length === 0) return true;

  const announcementIsSet = announcements.some(a => a.code === code);
  const answers = answersForQuestion(question, questionResponses);
  const answersEnableAnnouncement = answers.some(a => a.announcements?.includes(code));
  return (
    announcementIsSet === answersEnableAnnouncement || !isQuestionStable({ question, questionnaire, questionResponses })
  );
};

/**
 * Returns a boolean representing whether or not the question is required to be answered in order to make the form valid
 */
export const isQuestionRequired = (
  question: QEQuestion,
  questionResponses: QuestionState[],
  questionnaire: QEQuestion[]
): boolean =>
  !question.optional ||
  findParents(question, questionnaire).some(
    parentQuestion =>
      answersForQuestion(parentQuestion, questionResponses).some(
        parentAnswer =>
          parentAnswer.requiredQuestions?.includes(question.guid) ||
          parentAnswer.nestedQuestions?.includes(question.guid)
      ) &&
      // Recursion
      isQuestionRequired(parentQuestion, questionResponses, questionnaire)
  );

export const isAnnouncementSet = (code: string, announcements: AnnouncementState[]) =>
  announcements.some(an => an.code === code);

export const answersForQuestion = (question: QEQuestion, questionResponses: QuestionState[]) => {
  const answerValues = questionResponses.find(qr => qr.guid === question.guid)?.answers.map(ad => ad.value) || [];
  return question.answers.filter(answer => answerValues.includes(answer.value));
};

export const getQuestionsByAnnouncementCode = (questionnaire: QEQuestion[]) => {
  return cacheByQuestionnaire(questionnaire, 'questionsByAnnouncementCode', () =>
    questionnaire.reduce<{ [code: string]: QEQuestion[] }>((acc, question) => {
      return question.answers.reduce<{ [code: string]: QEQuestion[] }>((mapping, answer) => {
        if (answer.announcements) {
          // Iterate over the announcements related to each question in the questionnaire
          // Return an object where keys are announcement codes
          // and values are an array of questions related to the announcement code
          return answer.announcements.reduce(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (codeMap, code) => ({ ...codeMap, [code]: [...(codeMap[code] || []), question] }),
            mapping
          );
        }
        return mapping;
      }, acc);
    }, {})
  );
};

export type AnnouncementMappingResult = { code: string; value: boolean; questions: QEQuestion[] };
export const calculateCurrentStateOfAnnouncements = (
  questionnaire: QEQuestion[],
  questionResponses: QuestionState[],
  announcements: AnnouncementState[]
) => {
  const questionsByAnnouncementCode = getQuestionsByAnnouncementCode(questionnaire);

  return Object.entries(questionsByAnnouncementCode).reduce<AnnouncementMappingResult[]>((acc, [code, questions]) => {
    const anyQuestionSetsAnnouncement = questions.some(q =>
      answersForQuestion(q, questionResponses).some(ans => ans.announcements?.includes(code))
    );

    if (anyQuestionSetsAnnouncement) {
      return [...acc, { code, value: true, questions }];
    }

    const allQuestionsUnsetAnnouncement = questions.every(question =>
      isQuestionStable({ question, questionnaire, questionResponses })
    );

    return [
      ...acc,
      ...(allQuestionsUnsetAnnouncement
        ? [{ code, value: false, questions }]
        : // Not enough information, keep what we had
          announcements.filter(a => a.code === code).map(() => ({ code, value: true, questions })))
    ];
  }, []);
};

export const getQuestionByGuid = (guid: string, questionnaire: QEQuestion[]): QEQuestion => {
  const questionsByGuid = cacheByQuestionnaire(questionnaire, 'questionsByGuid', () =>
    questionnaire.reduce<{ [guid: string]: QEQuestion }>((acc, question) => ({ ...acc, [question.guid]: question }), {})
  );

  return questionsByGuid[guid]!;
};

export const isSourceLocked = (announcerSource: ClientAnnouncerSource, currentSource: string) => {
  const lockedSources = announcerSource === 'seller' ? ['inspector', 'experian'] : ['experian'];
  return lockedSources.includes(currentSource);
};

/**
 * Returns a boolean representing whether or not a specific answer relates to a specific announcement
 */
export const isAnswerRelatedToAnnouncement = (answer: QEAnswer, code: string, questionnaire: QEQuestion[]) => {
  const relatedAnnouncementsByAnswer = cacheByQuestionnaire(
    questionnaire,
    'relatedAnnouncementsByAnswer',
    () =>
      new Map(
        questionnaire.reduce<[QEAnswer, string[]][]>(
          (acc, question) => [
            ...acc,
            ...question.answers.map<[QEAnswer, string[]]>(a2 => [
              a2,
              [
                a2,
                ...(a2.requiredQuestions ?? [])
                  .concat(a2.nestedQuestions ?? [])
                  .map(guid => getQuestionByGuid(guid, questionnaire))
                  .flatMap(q => [q, ...findChildren(q, questionnaire)])
                  .flatMap(q => q.answers)
              ].flatMap(a => a.announcements || [])
            ])
          ],
          []
        )
      )
  );

  return !!relatedAnnouncementsByAnswer.get(answer)?.includes(code);
};

/**
 * Returns a boolean representing whether or not a specific question has answers that relate to a specific announcement
 */
export const isQuestionRelatedToAnnouncement = (question: QEQuestion, code: string, questionnaire: QEQuestion[]) => {
  const relatedAnnouncementsByGuid = cacheByQuestionnaire(questionnaire, 'relatedAnnouncementsByGuid', () =>
    questionnaire.reduce<{ [guid: string]: string[] }>(
      (acc, q) => ({
        ...acc,
        [q.guid]: [
          ...[q, ...findChildren(q, questionnaire)].flatMap(cq => cq.answers.flatMap(a => a.announcements || []))
        ]
      }),
      {}
    )
  );

  return relatedAnnouncementsByGuid[question.guid].includes(code);
};

export const applyFormFilters = async (
  questionnaire: Questionaire,
  tags: string[],
  userType: string[],
  unitPromise: Promise<Unit> | undefined,
  useDDS: boolean | undefined
) => {
  const allTags: string[] =
    unitPromise && questionnaire.autoTag ? [...tags, ...(await computeVehicleTags(await unitPromise, useDDS))] : tags;

  const preserveResponsesForTheseQuestions: QEQuestion[] = [];
  let questions = questionnaire.items;

  //had to add this conditional for an announcement scene integration test to pass,
  //there is never a scenerio where you wouldn't have more than one question and the announcement scene even null checks questionnaire length before calling this function

  if (questions.length > 1) {
    const tagsAndUserTypeFilteredQuestions = questions.reduce<QEQuestion[]>((acc, question) => {
      const answers = question.answers.filter(a => a.userType?.some(type => userType.includes(type)) !== false);
      const shouldNotFilterOutQuestion =
        answers.length > 0 && question.userType?.some(type => userType.includes(type)) !== false;
      return shouldNotFilterOutQuestion ? [...acc, { ...question, answers }] : acc;
    }, []);
    questions = tagsAndUserTypeFilteredQuestions;
  }

  if (!allTags.length) return { questions, allTags };

  const questionsFilteredByTag = questions.reduce<QEQuestion[]>((acc, question) => {
    const answers = question.answers.filter(a => a.tags?.some(tag => allTags.includes(tag)) !== false);
    const shouldNotFilterOutQuestion =
      answers.length > 0 && question.tags?.some(tag => allTags.includes(tag)) !== false;

    if (!shouldNotFilterOutQuestion && question.tags?.some(tag => tag === 'OBD:True')) {
      preserveResponsesForTheseQuestions.push(question);
    }

    return shouldNotFilterOutQuestion ? [...acc, { ...question, answers }] : acc;
  }, []);
  const questionsWithFilteredAnswerRequiredQuestions = questionsFilteredByTag.map(question => {
    const mappedAnswers = question.answers.map(answer => {
      const filteredRequiredQuestions = answer.requiredQuestions?.filter(reqQuestion =>
        questionsFilteredByTag.some(q => q.guid === reqQuestion)
      );
      return {
        ...answer,
        requiredQuestions: filteredRequiredQuestions?.length ? filteredRequiredQuestions : undefined
      };
    });
    return { ...question, answers: mappedAnswers };
  });

  questions = questionsWithFilteredAnswerRequiredQuestions;

  return { questions, allTags, preserveResponsesForTheseQuestions };
};

export const getDefaultQuestionResponses = (questionnaire: QEQuestion[]) =>
  questionnaire.reduce<QuestionState[]>(
    (acc, q) =>
      q.defaultAnswers
        ? [
            ...acc,
            {
              guid: q.guid,
              answers: q.defaultAnswers.map(da => ({ value: da, selectedByDefault: true })),
              source: 'gfb_question'
            }
          ]
        : acc,
    []
  );

export const calculatePreservedResponses = (questionsToPreserve: QEQuestion[], questionResponses: QuestionState[]) => {
  return questionsToPreserve.reduce((acc, question) => {
    const originalResponse = questionResponses.find(response => response.guid === question.guid);
    if (!originalResponse) {
      return acc;
    }

    return [...acc, originalResponse];
  }, [] as QuestionState[]);
};
