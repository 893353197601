import { logger } from '../logger';
import { AppConfiguration, CLIENT_ANNOUNCER_SOURCES } from '../models/AppConfiguration';
import { ENVIRONMENT_NAMES } from '../models/EnvironmentName';
import { createTheme } from '../styles/theme';

export const verifyAppConfiguration = (config: Partial<AppConfiguration>) => {
  const errors = [];
  const requiredKeys: (keyof AppConfiguration)[] = [
    'clientApplicationName',
    'authToken',
    'rootElementId',
    'announcerSource',
    'environment',
    'initialState',
    'onStateChange'
  ];
  requiredKeys.forEach(key => {
    if (!config[key]) {
      errors.push(`Missing "${key}" in configuration`);
    }
  });
  if (!config.environment || !ENVIRONMENT_NAMES.includes(config.environment)) {
    errors.push(
      `Invalid environment "${
        config.environment
      }" in configuration. Environment must be one of: ${ENVIRONMENT_NAMES.join(', ')}`
    );
  }
  if (!config.announcerSource || !CLIENT_ANNOUNCER_SOURCES.includes(config.announcerSource)) {
    errors.push(
      `Invalid announcerSource "${
        config.announcerSource
      }" in configuration. Announcer source must be one of: ${CLIENT_ANNOUNCER_SOURCES.join(', ')}`
    );
  }
  if (!config.initialState?.announcements) {
    errors.push('Missing "initialState.announcements" in configuration');
  }
  if (!config.initialState?.questionResponses) {
    errors.push('Missing "initialState.questionResponses" in configuration');
  }
  const allowedColors = Object.keys(createTheme({}).color);
  errors.push(
    ...Object.keys(config.colors || {})
      .filter(color => !allowedColors.includes(color))
      .map(color => `Invalid color "${color}" in configuration. Allowed colors are: ${allowedColors.join(', ')}`)
  );
  if (errors.length > 0) {
    logger.error('verifyAppConfiguration', { errors });
    throw new Error(`Invalid configuration:\n* ${errors.join('\n* ')}`);
  }
};
