import { fetchSellerDescription } from '../api/fetchSellerDescription';
import { fetchVehicleInformation } from '../api/fetchVehicleInformation';
import { Unit } from '../models/Unit';

export const computeTagsFromVDS = async (unit: Unit) => {
  const [vehicleDescriptions, sellerDescription] = await Promise.all([
    fetchVehicleInformation(unit.vin),
    fetchSellerDescription(unit.sellerDescriptions.href)
  ]);

  // On 7/30/21 we made a change to VDS to use build/chrome(trim.value) trim instead of valuations trim(mmr.trim)
  // This caused vehicles which had units/seller descriptions created before 7/30 to not match
  // on the trim check below when we were only looking at trim.value
  // If you are reading this and it is many many months after 7/30/21 we can probably stop
  // checking for a match with mmr.trim
  return (
    vehicleDescriptions.find(
      item => item.trim.value === sellerDescription?.trim || (item.mmr && item.mmr.trim === sellerDescription?.trim)
    )?.tags || []
  );
};
