import React, { useState, useEffect, useMemo } from 'react';
import { AppConfiguration } from '../../models/AppConfiguration';
import { Announcement } from '../../models/Announcement';
import { fetchAnnouncementDictionary } from '../../api/fetchAnnouncementDictionary';
import { AnnouncementState, QuestionState } from '../../models/AppState';
import { QEQuestion } from '../../models/QEQuestion';
import { fetchQuestionnaire } from '../../api/fetchQuestionnaire';
import { AnnouncementItem } from './AnnouncementItem';
import { isSourceLocked, calculatePreservedResponses, applyFormFilters } from '../../helpers/mappingHelpers';
import { createAppState } from '../../helpers/createAppState';
import styled from 'styled-components';
import { injectMappingsIntoOldForm } from '../../helpers/injectMappingsIntoOldForm';
import { logger } from '../../logger';
import { computeWorldState } from '../../helpers/computeWorldState';
import { fetchUnit } from '../../api/fetchUnit';

export const GREEN_LIGHT_CODE = 'AN0002';
export const RED_LIGHT_CODE = 'AN0003';
export const YELLOW_LIGHT_CODE = 'AN0004';
export const OPPOSING_CODES: Record<string, string | undefined> = {
  [RED_LIGHT_CODE]: GREEN_LIGHT_CODE,
  [GREEN_LIGHT_CODE]: RED_LIGHT_CODE
};
const LIGHT_CODES = [GREEN_LIGHT_CODE, RED_LIGHT_CODE, YELLOW_LIGHT_CODE];

export const AnnouncementsScene = (props: AppConfiguration) => {
  const [loaded, setLoaded] = useState(false);
  const [questionnaireHref, setQuestionnaireHref] = useState('');
  const [questionnaire, setQuestionnaire] = useState<QEQuestion[]>([]);
  const [announcementDictionary, setAnnouncementDictionary] = useState<Announcement[]>([]);
  const [announcements, setAnnouncements] = useState(props.initialState.announcements);
  const [questionResponses, setQuestionResponses] = useState(props.initialState.questionResponses);
  const [preservedResponses, setPreservedResponses] = useState<QuestionState[]>([]);
  const [prompts, setPrompts] = useState<string[]>([]);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [client, setClient] = useState('');

  // Performance tradeoff:
  // Automaticaly fetch the unit just in case we need it, if we don't need
  // the unit, we will have wasted a units call, but it's worth the extra call
  // to avoid boot-up time in the QNA UI
  const unitPromise = useMemo(() => (props.unitHref ? fetchUnit(props.unitHref) : undefined), [props.unitHref]);

  useEffect(() => {
    (async () => {
      const [dictionaryResponse, questionnaireResponse] = await Promise.all([
        Promise.resolve(
          (props.debug && props.debugDictionary) ||
            fetchAnnouncementDictionary().catch(error => {
              logger.error('announcementsDictionary:fetch', { error });
              setDisplayErrorMessage(true);
              return [];
            })
        ),
        // Do not fetch the questionnaire when no questionResponses have been provided
        // We don't want to prompt the user for any questions when there is no disclosure
        Promise.resolve(
          questionResponses.length
            ? Promise.resolve(
                (props.debug && props.debugQuestionnaire) ||
                  fetchQuestionnaire(props.questionnaireHref).catch(error => {
                    logger.error('questionnaire:fetch', { error });
                    setDisplayErrorMessage(true);
                  })
              )
            : undefined
        )
      ]);
      // Re-sort the dictionary with our own special rules:
      // Green Light, Red Light, and Yellow Light on top
      // the rest sorted alphabetically
      const sortedAnnouncements = dictionaryResponse.sort((a, b) => {
        if (LIGHT_CODES.includes(a.code) && LIGHT_CODES.includes(b.code)) {
          return 0;
        } else if (LIGHT_CODES.includes(a.code)) {
          return -1;
        } else if (LIGHT_CODES.includes(b.code)) {
          return 1;
        } else {
          return a.text.localeCompare(b.text);
        }
      });

      setAnnouncementDictionary(sortedAnnouncements);
      if (questionnaireResponse) {
        const { questions, allTags, preserveResponsesForTheseQuestions } = await applyFormFilters(
          questionnaireResponse,
          props.tags || [],
          props.userType || [],
          unitPromise,
          props.useDDS !== undefined ? props.useDDS : true
        );
        setQuestionnaire(injectMappingsIntoOldForm(questionnaireResponse.href, questions));
        setQuestionnaireHref(questionnaireResponse.href);
        setTags(allTags);
        setClient(questionnaireResponse.formName?.split(' ')[0].toLowerCase() || '');

        preserveResponsesForTheseQuestions &&
          setPreservedResponses(
            calculatePreservedResponses(preserveResponsesForTheseQuestions, props.initialState.questionResponses)
          );
      }
    })().finally(() => setLoaded(true));
  }, []);

  const worldState = !displayErrorMessage
    ? computeWorldState({
        questionnaire,
        questionResponses,
        preservedResponses,
        announcements,
        initialAnnouncements: props.initialState.announcements,
        announcerSource: props.announcerSource,
        tags,
        userType: props.userType || []
      })
    : undefined;

  useEffect(() => {
    if (loaded && worldState && !displayErrorMessage) {
      props.onStateChange(
        createAppState({
          prompting: prompts.length > 0,
          questionnaireHref,
          worldState
        })
      );
    }
  }, [worldState, announcementDictionary, announcements, questionnaire, prompts, questionnaireHref, loaded]);

  const addAnnouncement = (currentAnnouncements: AnnouncementState[], code: string) => {
    const originalAnnouncement = props.initialState.announcements.find(ann => ann.code === code);

    return [
      ...currentAnnouncements.filter(ann => ann.code !== code),
      {
        code,
        source: originalAnnouncement?.source || props.announcerSource,
        username: originalAnnouncement?.username || props.username
      }
    ];
  };
  const removeAnnouncement = (currentAnnouncements: AnnouncementState[], code: string) => {
    return currentAnnouncements.filter(ann => ann.code !== code);
  };

  const announcementIsCurrentlyOpposedBy = (announcement: Announcement) => {
    const opposingCode = OPPOSING_CODES[announcement.code];
    const opposingAnnouncementSource = announcements.find(ann => ann.code === opposingCode)?.source;
    return opposingCode && opposingAnnouncementSource
      ? announcementDictionary.find(ann => ann.code === opposingCode)
      : undefined;
  };

  const handleAnnouncementChange = (announcement: Announcement) => {
    const currentAnnouncementIsInState = announcements.find(ann => ann.code === announcement.code);
    let newAnnouncements = announcements;
    const opposingAnnouncement = announcementIsCurrentlyOpposedBy(announcement);
    if (!currentAnnouncementIsInState && opposingAnnouncement) {
      const userResponse = confirm(
        `This change will remove the following announcement:\n\n ${opposingAnnouncement.text}`
      );
      if (!userResponse) {
        return;
      }
      newAnnouncements = removeAnnouncement(newAnnouncements, opposingAnnouncement.code);
    }

    if (currentAnnouncementIsInState) {
      newAnnouncements = removeAnnouncement(newAnnouncements, announcement.code);
    } else {
      newAnnouncements = addAnnouncement(newAnnouncements, announcement.code);
    }

    setAnnouncements(newAnnouncements);
  };

  const handleQuestionsChange = (newResponses: QuestionState[]) => {
    setQuestionResponses(newResponses);
  };

  const handlePrompting = (announcement: Announcement, announcementIsPrompting: boolean) => {
    setPrompts(originalPrompts =>
      announcementIsPrompting
        ? [...originalPrompts, announcement.code]
        : originalPrompts.filter(prompt => prompt !== announcement.code)
    );
  };

  const isAnnouncementDisabled = (announcement: Announcement) => {
    const announcementSource = announcements.find(ann => ann.code === announcement.code)?.source;
    if (announcementSource && isSourceLocked(props.announcerSource, announcementSource)) {
      return true;
    }
    const opposingAnnouncement = announcementIsCurrentlyOpposedBy(announcement);
    const opposingAnnouncementSource = announcements.find(ann => ann.code === opposingAnnouncement?.code)?.source;
    return !!(opposingAnnouncementSource && isSourceLocked(props.announcerSource, opposingAnnouncementSource));
  };

  return !loaded ? null : (
    <Container>
      {!worldState || displayErrorMessage ? (
        <ErrorText data-test-id="error-text">An error has occurred, please try again later.</ErrorText>
      ) : (
        announcementDictionary.map(announcement => (
          <AnnouncementItem
            client={client}
            key={announcement.code}
            readonly={props.readonly}
            disabledBySource={isAnnouncementDisabled(announcement)}
            announcement={announcement}
            username={props.username}
            onAnnouncementChange={handleAnnouncementChange}
            onQuestionsChange={handleQuestionsChange}
            onPrompting={handlePrompting}
            announcementDictionary={announcementDictionary}
            worldState={worldState}
          />
        ))
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: ${({ theme }) => theme.typography.family};
  font-size: ${({ theme }) => theme.typography.size.md};
  color: ${({ theme }) => theme.color.textColor};
`;

const ErrorText = styled.label`
  font-weight: bold;
  padding-bottom: ${({ theme }) => theme.space.sm};
`;
