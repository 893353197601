import axios from 'axios';
import { VDSDescriptionData } from '../models/VehicleDescription';

export type VDSResponse = {
  vehicleInformation: VDSDescriptionData[];
};

export const fetchVehicleInformation = async (vin: string) => {
  const response = await axios.get<VDSResponse>(`/vehicleDataService/vin/${vin}?autoTag=true`);
  return response.data.vehicleInformation;
};
