/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { Question } from './Question';
import { AppConfiguration } from '../../models/AppConfiguration';
import { DamageGuides, QEQuestion } from '../../models/QEQuestion';
import { Announcement } from '../../models/Announcement';
import { QuestionState, AnnouncementState, TakePhotoDetails, AlertMessageDetails } from '../../models/AppState';
import { fetchAnnouncementDictionary } from '../../api/fetchAnnouncementDictionary';
import { fetchQuestionnaire } from '../../api/fetchQuestionnaire';
import styled from 'styled-components';
import {
  answersForQuestion,
  calculateCurrentStateOfAnnouncements,
  removeResponsesForOptionalQuestions,
  isSourceLocked,
  isAnswerRelatedToAnnouncement,
  getDefaultQuestionResponses,
  calculatePreservedResponses,
  getQuestionByGuid,
  applyFormFilters
} from '../../helpers/mappingHelpers';
import { createAppState } from '../../helpers/createAppState';
import { PrimaryButton } from '../../components/Buttons';
import { injectMappingsIntoOldForm } from '../../helpers/injectMappingsIntoOldForm';
import { HelpText } from '../../components/HelpText';
import { SubSectionText } from '../../components/SubSectionText';
import { logger } from '../../logger';
import { computeWorldState } from '../../helpers/computeWorldState';
import { fetchUnit } from '../../api/fetchUnit';
import WebFont from 'webfontloader';
import { handleAnswerChange, handleQuantityAnswerChange } from '../../helpers/handleAnswerChange';
import { EnvironmentName } from '../../models/EnvironmentName';

export const QUESTIONNAIRE_HREF_OVERRIDES = (environment: EnvironmentName, manHeimAccountNumber: string) => {
  const formGuid =
    environment === 'production' ? '9fe83329-a51d-44d4-8f67-28fb801451c6' : '4e41e5fc-a91f-4788-a12b-3103bf3fb49e';
  const hrefForm: Readonly<Record<string, string>> = {
    '4995547': `/forms/id/${formGuid}`,
    '4996557': `/forms/id/${formGuid}`
  };
  return hrefForm[manHeimAccountNumber];
};

export const QuestionsScene = (props: AppConfiguration) => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins:600']
      }
    });
  }, []);

  const [loaded, setLoaded] = useState(false);
  const [questionnaireHref, setQuestionnaireHref] = useState('');
  const [questionnaire, setQuestionnaire] = useState<QEQuestion[]>([]);
  const [announcementDictionary, setAnnouncementDictionary] = useState<Announcement[]>([]);
  const [questionResponses, setQuestionResponses] = useState<QuestionState[]>([]);
  const [preservedResponses, setPreservedResponses] = useState<QuestionState[]>([]);
  const [announcements, setAnnouncements] = useState<AnnouncementState[]>(props.initialState.announcements);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [damageGuides, setDamageGuides] = useState<DamageGuides>();
  const [overlayActive, setOverlayActive] = useState(false);
  const userType = props.userType || [];
  const [client, setClient] = useState('');

  // Performance tradeoff:
  // Automaticaly fetch the unit just in case we need it, if we don't need
  // the unit, we will have wasted a units call, but it's worth the extra call
  // to avoid boot-up time in the QNA UI
  const unitPromise = useMemo(() => (props.unitHref ? fetchUnit(props.unitHref) : undefined), [props.unitHref]);

  useEffect(() => {
    (async () => {
      const [dictionaryResponse, questionnaireResponse] = await Promise.all([
        Promise.resolve((props.debug && props.debugDictionary) || fetchAnnouncementDictionary()),
        Promise.resolve(
          (props.debug && props.debugQuestionnaire) ||
            fetchQuestionnaire(
              props.questionnaireHref ||
                (unitPromise
                  ? QUESTIONNAIRE_HREF_OVERRIDES(props.environment, (await unitPromise).contact.manheimAccountNumber)
                  : undefined)
            )
        )
      ]);

      const { questions, allTags, preserveResponsesForTheseQuestions } = await applyFormFilters(
        questionnaireResponse,
        props.tags || [],
        userType,
        unitPromise,
        props.useDDS !== undefined ? props.useDDS : true
      );
      // Cleanup and verify the client's initial question responses
      // Remove any answers that are deemed "invalid" or that don't agree with
      // the announcements provided by the client
      const initialAndDefaultResponses = getDefaultQuestionResponses(questions).reduce(
        (acc, qr) => (acc.some(r => r.guid === qr.guid) ? acc : [...acc, qr]),
        props.initialState.questionResponses
      );
      const calculatedQuestionResponses = initialAndDefaultResponses.reduce<QuestionState[]>(
        (acc, { guid, source, answers }) => {
          // Make sure the GUID still exists in the questionnaire
          const matchingQuestion = questions.find(q => q.guid === guid);
          if (!matchingQuestion) {
            return acc;
          }

          // only accept answers that exist in the dictionary
          const matchingAnswers = answersForQuestion(matchingQuestion, initialAndDefaultResponses).map(
            ma => answers.find(a => a.value === ma.value)!
          );
          return matchingAnswers.length ? [...acc, { guid, answers: matchingAnswers, source }] : acc;
        },
        []
      );
      preserveResponsesForTheseQuestions &&
        setPreservedResponses(
          calculatePreservedResponses(preserveResponsesForTheseQuestions, props.initialState.questionResponses)
        );

      setTags(allTags);
      setQuestionResponses(calculatedQuestionResponses);
      setAnnouncementDictionary(dictionaryResponse);
      setQuestionnaire(injectMappingsIntoOldForm(questionnaireResponse.href, questions));
      setQuestionnaireHref(questionnaireResponse.href);
      setDamageGuides(questionnaireResponse.damageGuides);
      setClient(questionnaireResponse.formName?.split(' ')[0].toLowerCase() || '');
    })()
      .catch(error => {
        logger.error('questions:boot', { error });
        setDisplayErrorMessage(true);
        if (process.env.NODE_ENV === 'development') throw error;
      })
      .finally(() => setLoaded(true));
  }, []);

  const worldState = computeWorldState({
    questionnaire,
    questionResponses,
    preservedResponses,
    announcements,
    initialAnnouncements: props.initialState.announcements,
    announcerSource: props.announcerSource,
    tags,
    userType
  });

  useEffect(() => {
    if (loaded && !displayErrorMessage) {
      props.onStateChange(
        createAppState({
          prompting: false,
          questionnaireHref,
          worldState
        })
      );
    }
    window.addEventListener('message', handleMessage, true);
    return () => {
      window.removeEventListener('message', handleMessage, true);
    };
  }, [worldState, announcements, questionnaire, questionnaireHref, loaded]);

  const handleMessage = (e: any) => {
    if (typeof e.data === 'string') {
      const message = JSON.parse(e.data);
      if (message.type === 'removeAnswer') {
        const question = getQuestionByGuid(message.questionGuid, worldState.questionnaire);
        const previousSelectedAnswers = worldState.answersForQuestion(question);
        const answer = previousSelectedAnswers.find(ans => ans.value === message.answerValue);

        if (answer) {
          const newResponses = handleAnswerChange(
            worldState.questionResponses,
            answer,
            question,
            previousSelectedAnswers,
            undefined,
            undefined,
            undefined,
            undefined,
            true
          );
          newResponses && handleChange(newResponses);
        }
      } else if (message.type === 'photoCaptured') {
        const newResponses = worldState.questionResponses.map(qr =>
          qr.guid === message.questionGuid
            ? {
                ...qr,
                answers: qr.answers.map(a => (a.value === message.answer ? { ...a, photoCaptured: true } : a))
              }
            : qr
        );
        handleChange(newResponses);
      } else if (message.type === 'removeDamageAnswer') {
        const responses = worldState.questionResponses;

        const question = getQuestionByGuid(message.messageDetails.questionGuid, worldState.questionnaire);
        const parentQuestion = message.messageDetails.parentQuestion
          ? getQuestionByGuid(message.messageDetails.parentQuestion, worldState.questionnaire)
          : undefined;
        const parentAnswer =
          message.messageDetails.parentAnswer && parentQuestion
            ? parentQuestion.answers.find(ans => ans.value === message.messageDetails.parentAnswer)
            : undefined;
        const previousSelectedAnswers = worldState.answersForQuestion(question);
        const previousSelectedParentAnswers = parentQuestion
          ? worldState.answersForQuestion(parentQuestion)
          : undefined;
        const answer = question.answers.find(ans => ans.value === message.messageDetails.newAnswer);
        if (answer) {
          if (message.messageDetails.quantity) {
            const newResponses = handleQuantityAnswerChange(
              responses,
              answer,
              message.messageDetails.quantity,
              question,
              previousSelectedAnswers
            );
            newResponses && handleChange(newResponses);
          } else {
            const newResponses = handleAnswerChange(
              responses,
              answer,
              question,
              previousSelectedAnswers,
              parentAnswer,
              parentQuestion,
              undefined,
              previousSelectedParentAnswers,
              true
            );
            newResponses && handleChange(newResponses);
          }
        }
      }
    }
  };

  const autoFillAnswers = (random = false) => {
    const newResponses = questionnaire.reduce<QuestionState[]>((acc, question) => {
      const mappedAnswers = question.answers.filter(a =>
        props.initialState.announcements.some(ann => isAnswerRelatedToAnnouncement(a, ann.code, questionnaire))
      );

      const happyAnswer = question.answers.find(a => a.connotation >= 0);
      const randomAnswer = question.answers[Math.floor(Math.random() * question.answers.length)];
      const alternateAnswer = random ? randomAnswer : happyAnswer || question.answers[0];
      const newAnswers = mappedAnswers.length ? mappedAnswers : [alternateAnswer];
      return [
        ...acc.filter(i => i.guid !== question.guid),
        {
          guid: question.guid,
          answers: (question.type === 'multi' ? newAnswers : [newAnswers[0]]).map(a => ({
            value: a.value,
            ...(a.chooseQuantity ? { quantity: Math.round(Math.random() * 10) + 1 } : undefined)
          })),
          source: 'gfb_question'
        }
      ];
    }, []);
    handleChange(newResponses);
  };

  const handleChange = (newResponses: QuestionState[]) => {
    const mappingsForAnnouncement = calculateCurrentStateOfAnnouncements(
      questionnaire,
      removeResponsesForOptionalQuestions(newResponses, questionnaire),
      announcements
    );
    const newAnnouncementState = mappingsForAnnouncement.reduce((acc, mapping) => {
      const withoutCurrentCode = acc.filter(announcement => announcement.code !== mapping.code);
      const originalAnnouncement = props.initialState.announcements.find(a => a.code === mapping.code);
      const wouldRemoveAndIsSourceLocked =
        originalAnnouncement && isSourceLocked(props.announcerSource, originalAnnouncement.source);
      if (mapping.value || wouldRemoveAndIsSourceLocked) {
        return [
          ...withoutCurrentCode,
          {
            code: mapping.code,
            source: originalAnnouncement?.source || props.announcerSource,
            username: originalAnnouncement?.username || props.username
          }
        ];
      } else {
        return withoutCurrentCode;
      }
    }, announcements);

    setQuestionResponses(newResponses);
    setAnnouncements(newAnnouncementState);
  };

  let lastSubsection: string | undefined = undefined;
  const showSubSection = (currentSubSection = '') => {
    const result = !!currentSubSection && lastSubsection !== currentSubSection;
    lastSubsection = currentSubSection;
    return result;
  };

  const handlePhotoButtonClick = (takePhotoDetails: TakePhotoDetails) => {
    const appState = createAppState({
      prompting: false,
      questionnaireHref,
      worldState
    });
    props.onStateChange({ ...appState, takePhotoDetails });
  };

  const sendShowAlertMessage = (alertMessageDetails: AlertMessageDetails) => {
    const appState = createAppState({
      prompting: false,
      questionnaireHref,
      worldState
    });
    props.onStateChange({ ...appState, alertMessageDetails });
  };

  return !loaded ? null : (
    <Container>
      {props.debug && (
        <DebugContainer>
          <h3>Debug Options 🦟</h3>
          <DebugButtons>
            <PrimaryButton onClick={() => autoFillAnswers()}>Happy</PrimaryButton>
            <PrimaryButton onClick={() => autoFillAnswers(true)}>Random</PrimaryButton>
            <PrimaryButton onClick={() => setQuestionResponses(getDefaultQuestionResponses(questionnaire))}>
              Clear
            </PrimaryButton>
          </DebugButtons>
          <small>
            build: {process.env.GIT_SHA?.substr(0, 7) || 'localhost'} - {process.env.BUILD_TIMESTAMP || 'localhost'}
          </small>
        </DebugContainer>
      )}
      {overlayActive && <Overlay data-test-id="questionnaire-overlay" />}
      {displayErrorMessage ? (
        <ErrorText data-test-id="error-text">An error has occurred, please try again later.</ErrorText>
      ) : (
        questionnaire
          .filter(question => worldState.isQuestionRequired(question) && !worldState.isNestedQuestion(question))
          .map(question => (
            <React.Fragment key={question.id + '-' + question.guid}>
              {showSubSection(question.subSection) && <SubSectionText>{question.subSection}</SubSectionText>}
              <Question
                client={client}
                key={question.guid}
                worldState={worldState}
                question={question}
                onChange={handleChange}
                onLinkClick={props.onLinkClick}
                readonly={props.readonly}
                questionMessages={props.questionMessages}
                announcementDictionary={announcementDictionary}
                damageGuides={damageGuides}
                setOverlayActive={setOverlayActive}
                inlineImaging={props.inlineImaging}
                onPhotoButtonClick={handlePhotoButtonClick}
                sendAlertMessage={sendShowAlertMessage}
              />
            </React.Fragment>
          ))
      )}

      {!!worldState.removedAnnouncements.length && (
        <HelpText>
          These announcements will be removed
          <ul data-test-id="all-removed-announcements-list">
            {worldState.removedAnnouncements.map(a =>
              worldState.removedSourceLockedAnnouncements.includes(a) ? (
                <li style={{ color: 'red' }} key={a.code}>
                  {announcementDictionary.find(ann => ann.code === a.code)?.text} ({a.source})
                </li>
              ) : (
                <li key={a.code}>{announcementDictionary.find(ann => ann.code === a.code)?.text}</li>
              )
            )}
          </ul>
        </HelpText>
      )}
    </Container>
  );
};

const DebugContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.disabled};
  margin-bottom: ${({ theme }) => theme.space.lg};
  padding: ${({ theme }) => theme.space.sm};
  border-radius: ${({ theme }) => theme.radius.lg};
`;

const Overlay = styled.div`
  position: absolute;
  opacity: 0.25;
  background-color: black;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const DebugButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Container = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.typography.family};
  font-size: ${({ theme }) => theme.typography.size.md};
  color: ${({ theme }) => theme.color.textColor};
`;

const ErrorText = styled.label`
  font-weight: bold;
`;
